import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Template from '../../layouts/Template';
import { AboutSection, SkillsSection } from './_Styles';

const About = ({ data }) => (
	<Template title={`About | ${data.site.siteMetadata.title}`} desc="About me">
		<AboutSection>
			<div className="wrapper">
				<h1>About</h1>
				<p>
					Tyler Zey is an entrepreneur and senior fullstack engineer who lives in{' '}
					<a href="https://en.wikipedia.org/wiki/Oconomowoc,_Wisconsin" target="_blank" rel="noopener noreferrer">
						Oconomowoc, WI
					</a>
					. He grew up in De Pere, WI and went to Luther College for music and business. He has a master’s in{' '}
					<a href="https://photos.app.goo.gl/aLgNKddESDwg99Tx9" target="_blank" rel="noopener noreferrer">
						clarinet performance
					</a>
					, performed in the{' '}
					<a href="https://santafesymphony.org/" target="_blank" rel="noopener noreferrer">
						Santa Fe Symphony
					</a>
					, New Mexico Philharmonic, and played operas one summer with{' '}
					<a href="https://www.maestromaazel.com/bio" target="_blank" rel="noopener noreferrer">
						Lorin Maazel
					</a>
					. After pursuing a classic musical career, he started a{' '}
					<a href="https://www.easyagentpro.com/" target="_blank" rel="noopener noreferrer">
						high-growth marketing software company
					</a>
					, doubled it year over year while running the marketing, sales, and technology departments as CEO.
				</p>
				<p>
					He currently is a senior fullstack engineer writing a distributed system to power the business operations of{' '}
					<a href="https://equips.com">Equips</a>. Some of the current technologies being used to solve business problems are
					DynamoDB, Cognito, ElasticSearch, AWS TextTract (machine learning), Golang, Typescript, and Node. There are several
					services that consume the main api, but the primary app is written in React with Tailwind.
				</p>
				<p>
					He is passionate about building highly cost-efficient, well tested apps that are accessible and achieve business
					results. His primary goal is to create a great product, that is used, and contributes greatly to an organization.
				</p>
				<p>
					He is married to{' '}
					<a target="_blank" rel="noopener noreferrer" href="https://sophiazey.com">
						Sophia Zey
					</a>{' '}
					and has{' '}
					<a target="_blank" rel="noopener noreferrer" href="https://photos.app.goo.gl/98Q2BD9tUoGXM1if9">
						two dogs
					</a>
					.
				</p>
			</div>
		</AboutSection>
		<SkillsSection>
			<div className="wrapper">
				<h2>Skills</h2>
				<h5>Servers</h5>
				<div className="skills">
					<div className="skill">
						<a href="https://aws.amazon.com/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#aws</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/cloudformation/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#cloudformation</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/lambda/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#lambda</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/ec2/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#ec2</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/rds/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#rds</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/dynamodb/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#dynamodb</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/elasticbeanstalk/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#elasticbeanstalk</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://www.docker.com/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#docker</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/ses/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#ses</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://aws.amazon.com/cloudwatch/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#cloudwatch</div>
						</a>
					</div>
				</div>
				<h5>Backend</h5>
				<div className="skills">
					<div className="skill">
						<a href="https://nodejs.org/en/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#nodejs</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://elastic.co" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#elasticsearch</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://serverless.com" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#serverless</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://golang.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#go</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://www.apollographql.com/docs/apollo-server/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#apolloserver</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://laravel.com/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#laravel</div>
						</a>
					</div>
				</div>
				<h5>Dev Ops</h5>
				<div className="skills">
					<div className="skill">
						<a href="#" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#testing</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://en.wikipedia.org/wiki/Continuous_integration" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#continous_integration</div>
						</a>
					</div>
				</div>
				<h5>Javascript</h5>
				<div className="skills">
					<div className="skill">
						<a href="https://www.gatsbyjs.com" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#gatsby</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://nextjs.org" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#nextjs</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://electronjs.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#electron</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://graphql.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#graphql</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#react</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://redux.js.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#redux</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://vuejs.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#vue</div>
						</a>
					</div>
					<div className="skill">
						<a href="http://vanilla-js.com/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#es6</div>
						</a>
					</div>
				</div>
				<h5>Compilers</h5>
				<div className="skills">
					<div className="skill">
						<a href="https://webpack.js.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#webpack</div>
						</a>
					</div>
					<div className="skill">
						<a href="http://www.typescriptlang.org/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#typescript</div>
						</a>
					</div>
				</div>
				<h5>CSS Frameworks</h5>
				<div className="skills">
					<div className="skill">
						<a href="https://tailwindcss.com" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#tailwind</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://getbootstrap.com/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#bootstrap</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://www.styled-components.com/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#styled-components</div>
						</a>
					</div>
					<div className="skill">
						<a href="https://bulma.io/" target="_blank" rel="noopener noreferrer">
							<div className="skill__btn">#bulma</div>
						</a>
					</div>
				</div>
			</div>
		</SkillsSection>
	</Template>
);

About.propTypes = {
	data: PropTypes.object.isRequired,
};

export default About;

export const pageQuery = graphql`
	query AboutQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
